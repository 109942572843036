const hotelInfoTypes = {
  FETCHING: "HOTEL_INFO_FETCHING",
  DETAILS: "HOTEL_DETAILS",
  SELECT_ROOM: "SELECT_ROOM",
  DESELECT_ROOM: "DESELECT_ROOM",
  SELECT_ADDON: "SELECT_ADDON",
  DESELECT_ADDON: "DESELECT_ADDON",
  TOTAL_PEOPLE: "TOTAL_PEOPLE",
  TOTAL_ADULT: "TOTAL_ADULT",
  TOTAL_CHILD: "TOTAL_CHILD",
  SET_DATE: "SET_DATE",
  DATE_COUNT: "DATE_COUNT",
  RESET: "RESET",
  SELECT_RP: "SELECTED_RP",
  EDIT_RP: "EDIT_RP",
  DESELECT_RP: "DESELECT_RP",
  RESET_RP: "RESET_RP",
  ADD_REVIEW: "ADD_REVIEW",
  ADD_RATING: "ADD_RATING",
  ADD_HOTELADDON: "ADD_HOTELADDON",
  REMOVE_HOTELADDON: "REMOVE_HOTELADDON",
  PENDING_REASONS: "PENDING_REASONS",
  SET_INSTA_LINKS: "SET_INSTA_LINKS",
  SET_PROMOTION: "SET_PROMOTION",
  GET_FAQ: "GET_FAQ",
  GET_POLICY: "GET_POLICY",
  SET_PAYMENTS: "SET_PAYMENTS",
  FETCH_REVIEW: "FETCH_REVIEW",
  SHOW_HOURLY: "SHOW_HOURLY",
  SET_HOURLY_DATE: "SET_HOURLY_DATE",
  SET_SHOW_HOURLY: "SET_SHOW_HOURLY",
  SET_HOURLY_TIME: "SET_HOURLY_TIME",
  SET_SLOTS: "SET_SLOTS",
  SET_HOUR: "SET_HOUR",
  RESET_ROOM: "RESET_ROOM",
  SET_ROOM: "SET_ROOM",
  SET_IS_RATEPLAN: "SET_IS_RATEPLAN",
  SET_LIVE_PRICE: "SET_LIVE_PRICE",
  SET_TAXES: "SET_TAXES",
};

export default hotelInfoTypes;
