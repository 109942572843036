import dayjs from "dayjs";
import { lazy, Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import Loader from "./components/Loader/Loader";
import i18n from "./i18n";

import storageService from "./Services/storageService";
import { convert12hrTo24hr, tawkProps } from "./utils/utils";

const MainPage = lazy(() => import("./pages/MainPage/MainPage"));
const PageNotFound = lazy(() => import("./pages/PageNotFound/PageNotFound"));
const PaymentStatus = lazy(() => import("./pages/PaymentStatus/PaymentStatus"));
const PaymentPage = lazy(() => import("./pages/PaymentPage/PaymentPage"));
const App = lazy(() => import("./landing/App"));
const RoomView = lazy(() => import("./pages/RoomView/RoomView"));
const Checkout = lazy(() => import("./pages/CheckoutPage/CheckoutPage"));
const HotelPrivacyPolicy = lazy(() =>
  import("./components/PrivacyPolicy/HotelPrivacyPolicy")
);
const TripPlanner = lazy(() => import("./pages/Trip Planner/TripPlanner"));
const ReviewPage = lazy(() => import("./pages/ReviewPage/ReviewPage"));
const RedirectPage = lazy(() => import("./pages/RedirectPage/RedirectPage"));
const HotelTerms = lazy(() => import("./components/Terms/HotelTerms"));
const PrivacyIndex = lazy(() =>
  import("./components/PrivacyPolicy/PrivacyIndex")
);
const ChatPrivacy = lazy(() =>
  import("./components/PrivacyPolicy/ChatPrivacy")
);
const TermsIndex = lazy(() => import("./components/Terms/TermsIndex"));
const Move = lazy(() => import("./pages/RedirectPage/Move"));
const BookLanding = lazy(() =>
  import("./pages/BookMyStayLanding/BookMyStayLanding")
);
const Sitemaps = lazy(() => import("./pages/Sitemaps/Sitemaps"));

const Home = () => {
  const [isHost] = useState(
    window.location.host === "dev.hotelzify.com" ||
      window.location.host === "bookmystay.io" ||
      window.location.host === "www.bookmystay.io" ||
      window.location.host === "hotelzify.com" ||
      window.location.host === "13.127.148.46" ||
      window.location.host === "65.0.74.218" ||
      window.location.host === "service.prerender.io" ||
      window.location.host === "www.hotelzify.com" ||
      window.location.host === "localhost:3002" ||
      window.location.host === "localhost:3005" ||
      window.location.host === "localhost:3006" ||
      window.location.host === "172.168.122.20:3002" ||
      window.location.host ===
        "https://test-dev-website.s3.ap-south-1.amazonaws.com"
  );
  const [host] = useState(window.location.host);
  const [isBookMyStay] = useState(
    ["www.bookmystay.io", "bookmystay.io"].includes(window.location.host)
  );

  useEffect(() => {
    if (!isHost) {
      storageService.set("isDomain", true);
    } else {
      storageService.set("isDomain", false);
    }
  }, [isHost]);

  const setLanguage = () => {
    let locat = new URLSearchParams(new URL(window.location.href).search);
    let searchParams = Object.fromEntries(locat);
    let ln = searchParams?.["language"] || "en";
    storageService.set("lang", ln);
    i18n.changeLanguage(ln);
  };
  useEffect(() => {
    const link = document.createElement("link");
    link.href =
      "https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;700&display=swap";
    link.rel = "stylesheet";
    link.type = "text/css";
    document.head.appendChild(link);
  }, []);

  useEffect(() => {
    setLanguage();
  }, []);

  const {
    hotelData,
    reviews: rvs,
    faqs,
    policies,
  } = useSelector((state) => state.hotelInfo);
  const { reviews = [] } = rvs;

  const {
    name,
    description,
    HotelImages,
    address,
    longitude,
    latitude,
    HotelRooms,
    websiteSlug,
    domain,
    currency,
    state,
  } = hotelData;

  let leastPrice = HotelRooms?.length > 0 ? HotelRooms[0]?.defaultPrice : 0;

  const hotelzifyRichResults = [
    {
      "@context": "https://schema.org",
      "@type": "SoftwareApplication",
      name: "Hotelzify",
      operatingSystem: ["ANDROID", "IOS", "WEB"],
      applicationCategory: "TravelApplication",
      aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: "4.9",
        reviewCount: "62",
        bestRating: "5",
      },
      openingHoursSpecification: [
        {
          "@type": "OpeningHoursSpecification",
          dayOfWeek: [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
          ],
          opens: "00:00",
          closes: "23:59",
        },
      ],
    },
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Play Store",
          item: `https://play.google.com/store/apps/details?id=com.hotelzify.owners_app`,
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "App Store",
          item: `https://apps.apple.com/in/app/hotelzify/id1621346404`,
        },
        {
          "@type": "ListItem",
          position: 3,
          name: "Privacy Policy",
          item: `https://www.hotelzify.com/privacy-policy`,
        },
        {
          "@type": "ListItem",
          position: 4,
          name: "Terms and Conditions",
          item: `https://www.hotelzify.com/terms-conditions`,
        },
      ],
    },
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "How will I get bookings on my website?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Hotelzify are Google Hotel Partners which means we send your property prices and inventory to Google so that your property will rank higher with an official site tag. Apart from this, you can get more bookings by placing your website on social media and Whatsapp.",
          },
        },
        {
          "@type": "Question",
          name: "How much money do I need to begin?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Zero. We take money only when you get bookings. If you want a custom website however, please reach out to us and we would be happy to help.",
          },
        },
        {
          "@type": "Question",
          name: `Why should I have my own website?`,
          acceptedAnswer: {
            "@type": "Answer",
            text: "To save on commissions lost to OTAs  like Booking, MMT, Goibibo and let your guests book directly with you and to have your own branding.",
          },
        },
        {
          "@type": "Question",
          name: "What do I need to create my website?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Just download the Hotelzify app onto your mobile phone and in 4 steps, you will be ready to take bookings.",
          },
        },
        {
          "@type": "Question",
          name: "How are online payments accepted?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Payments are accepted via our partner Razorpay and settled to your account on checkin date. You just need to enter your bank details on the app and settlements would happen automatically.",
          },
        },
      ],
    },
  ];

  const richTextResults = [
    {
      "@context": "https://schema.org",
      "@type": "Hotel",
      image:
        HotelImages?.length > 0
          ? HotelImages[0]?.cdnImageUrl
          : "https://ik.imagekit.io/hotelzifyprod/logo.png",
      name: name,
      description: `${name} ${description}`,
      address: {
        "@type": "PostalAddress",
        streetAddress: address,
        addressLocality: address,
        addressRegion: state || "India",
        addressCountry: "India",
      },
      checkinTime: convert12hrTo24hr("12:00 PM"),
      checkoutTime: convert12hrTo24hr("11:00 AM"),
      // review: [
      //   {
      //     "@type": "Review",
      //     reviewRating: {
      //       "@type": "Rating",
      //       ratingValue: hotelData?.googleRating || "4",
      //       bestRating: "5",
      //     },
      //     author: {
      //       "@type": "Person",
      //       name: name,
      //     },
      //   },
      //   reviews?.map((rv) => {
      //     return {
      //       "@type": "Review",
      //       reviewRating: {
      //         "@type": "Rating",
      //         ratingValue: rv?.rating,
      //         bestRating: "5",
      //       },
      //       author: {
      //         "@type": "Person",
      //         name: rv?.author_name,
      //       },
      //     };
      //   }),
      // ],
      aggregateRating: {
        "@type": "AggregateRating",
        reviewCount: reviews?.user_ratings_total || 456,
        bestRating: 5,
        ratingValue: reviews?.rating || 4,
        worstRating: 1,
      },
      hasMap: `https://www.google.com/maps?ie=UTF8&hq&ll=${latitude},${longitude}&z=13`,
      // geo: {
      //   "@type": "GeoCoordinates",
      //   latitude: latitude,
      //   longitude: longitude,
      // },
      url: domain
        ? `https://${domain}`
        : `https://${websiteSlug}.bookmystay.io`,
      priceRange: `Starts from ${currency} ${leastPrice}`,
      // openingHoursSpecification: [
      //   {
      //     "@type": "OpeningHoursSpecification",
      //     dayOfWeek: [
      //       "Monday",
      //       "Tuesday",
      //       "Wednesday",
      //       "Thursday",
      //       "Friday",
      //       "Saturday",
      //       "Sunday",
      //     ],
      //     opens: "00:00",
      //     closes: "23:59",
      //   },
      // ],
    },
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 3,
          name: name + " official website",
          item: domain
            ? `https://${domain}`
            : `https://${websiteSlug}.bookmystay.io`,
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Privacy Policy",
          item: `https://${websiteSlug}.bookmystay.io/privacy-policy`,
        },
        {
          "@type": "ListItem",
          position: 1,
          name: "Terms and Conditions",
          item: `https://${websiteSlug}.bookmystay.io/terms-conditions`,
        },
      ],
    },
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        ...faqs?.map((faq) => {
          return {
            "@type": "Question",
            name: faq?.modifiedQuestion,
            acceptedAnswer: {
              "@type": "Answer",
              text: faq?.answer,
            },
          };
        }),
        {
          "@type": "Question",
          name: `What is the price range in ${name}`,
          acceptedAnswer: {
            "@type": "Answer",
            text: `As of ${dayjs().format(
              "DD/MM/YYYY"
            )} prices found for a 1-night stay at ${name} starts from ${currency} ${leastPrice}`,
          },
        },
        {
          "@type": "Question",
          name: `Is Early Check-in and Late Check-out available in ${name}?`,
          acceptedAnswer: {
            "@type": "Answer",
            text: "Early Check-in and Late Check-out is available based on availablity",
          },
        },
        {
          "@type": "Question",
          name: `Does ${name} offer free cancellation for a full refund?`,
          acceptedAnswer: {
            "@type": "Answer",
            text: `Yes, ${name} does have fully refundable room rates available to book on our site. If you’ve booked a fully refundable room rate, this can be cancelled up to a few days before check-in depending on the property's cancellation policy. Just make sure to check this property's cancellation policy for the exact terms and conditions.`,
          },
        },
      ],
    },
  ];

  const richTextResults2 = [
    {
      "@context": "https://schema.org",
      "@type": "VacationRental",
      additionalType: "HolidayVillageRental",
      identifier: name,
      containsPlace: {
        "@type": "Accommodation",
        additionalType: "EntirePlace",
        name: name,
        bed: [
          {
            "@type": "BedDetails",
            numberOfBeds: 1,
            typeOfBed: "Queen",
          },
          {
            "@type": "BedDetails",
            numberOfBeds: 2,
            typeOfBed: "Bunk",
          },
        ],
        occupancy: {
          "@type": "QuantitativeValue",
          value: 3,
        },
        image:
          HotelImages?.length > 0
            ? HotelImages?.map((im) => im?.cdnImageUrl)
            : "https://ik.imagekit.io/hotelzifyprod/logo.png",
        amenityFeature: [
          {
            "@type": "LocationFeatureSpecification",
            name: "ac",
            value: true,
          },
        ],
      },
      image:
        HotelImages?.length > 0
          ? HotelImages?.map((im) => im?.cdnImageUrl)
          : "https://ik.imagekit.io/hotelzifyprod/logo.png",
      name: name,
      description: `${name} ${description}`,
      address: {
        "@type": "PostalAddress",
        streetAddress: address,
        addressLocality: address,
        addressRegion: state || "India",
        addressCountry: "India",
      },
      checkinTime: convert12hrTo24hr("12:00 PM"),
      checkoutTime: convert12hrTo24hr("11:00 AM"),
      review: [
        {
          "@type": "Review",
          reviewRating: {
            "@type": "Rating",
            ratingValue: hotelData?.googleRating || "4",
            bestRating: "5",
          },
          datePublished: dayjs(hotelData?.created_dt).toISOString(),
          author: {
            "@type": "Person",
            name: name,
          },
        },
        reviews?.map((rv) => {
          return {
            "@type": "Review",
            reviewRating: {
              "@type": "Rating",
              ratingValue: rv?.rating,
              bestRating: "5",
            },
            datePublished: dayjs(rv?.time).toISOString(),
            author: {
              "@type": "Person",
              name: rv?.author_name,
            },
          };
        }),
      ],
      aggregateRating: {
        "@type": "AggregateRating",
        reviewCount: reviews?.user_ratings_total || 456,
        bestRating: 5,
        ratingValue: reviews?.rating || 4,
        worstRating: 1,
      },
      hasMap: `https://www.google.com/maps?ie=UTF8&hq&ll=${latitude},${longitude}&z=13`,
      geo: {
        "@type": "GeoCoordinates",
        latitude: latitude,
        longitude: longitude,
      },
      url: domain
        ? `https://${domain}`
        : `https://${websiteSlug}.bookmystay.io`,
      priceRange: `Starts from ${currency} ${leastPrice}`,
      // openingHoursSpecification: [
      //   {
      //     "@type": "OpeningHoursSpecification",
      //     dayOfWeek: [
      //       "Monday",
      //       "Tuesday",
      //       "Wednesday",
      //       "Thursday",
      //       "Friday",
      //       "Saturday",
      //       "Sunday",
      //     ],
      //     opens: "00:00",
      //     closes: "23:59",
      //   },
      // ],
    },
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 3,
          name: name + " official website",
          item: domain
            ? `https://${domain}`
            : `https://${websiteSlug}.bookmystay.io`,
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Privacy Policy",
          item: `https://${websiteSlug}.bookmystay.io/privacy-policy`,
        },
        {
          "@type": "ListItem",
          position: 1,
          name: "Terms and Conditions",
          item: `https://${websiteSlug}.bookmystay.io/terms-conditions`,
        },
      ],
    },
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        ...faqs?.map((faq) => {
          return {
            "@type": "Question",
            name: faq?.modifiedQuestion,
            acceptedAnswer: {
              "@type": "Answer",
              text: faq?.answer,
            },
          };
        }),
        {
          "@type": "Question",
          name: `What is the price range in ${name}`,
          acceptedAnswer: {
            "@type": "Answer",
            text: `As of ${dayjs().format(
              "DD/MM/YYYY"
            )} prices found for a 1-night stay at ${name} starts from ${currency} ${leastPrice}`,
          },
        },
        {
          "@type": "Question",
          name: `Is Early Check-in and Late Check-out available in ${name}?`,
          acceptedAnswer: {
            "@type": "Answer",
            text: "Early Check-in and Late Check-out is available based on availablity",
          },
        },
        {
          "@type": "Question",
          name: `Does ${name} offer free cancellation for a full refund?`,
          acceptedAnswer: {
            "@type": "Answer",
            text: `Yes, ${name} does have fully refundable room rates available to book on our site. If you’ve booked a fully refundable room rate, this can be cancelled up to a few days before check-in depending on the property's cancellation policy. Just make sure to check this property's cancellation policy for the exact terms and conditions.`,
          },
        },
      ],
    },
  ];

  useEffect(() => {
    window.location.href === "https://hotelzify.com/" ||
    window.location.href === "https://www.hotelzify.com/"
      ? generateElement(hotelzifyRichResults)
      : [10, 493, 1613, 1858].includes(hotelData?.id)
      ? generateElement(richTextResults2)
      : generateElement(richTextResults);
  }, [name, reviews, faqs]);

  const generateElement = (text) => {
    let script;

    if (document.getElementById("jsonld")) {
      script = document.getElementById("jsonld");
    } else {
      script = document.createElement("script");
    }
    script.type = "application/ld+json";
    script.id = "jsonld";
    script.innerHTML = JSON.stringify(text);
    window.document.body.appendChild(script);
  };

  return (
    <>
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) =>
              isHost ? (
                isBookMyStay ? (
                  <BookLanding />
                ) : (
                  <App />
                )
              ) : (
                <MainPage location={props} host={host} />
              )
            }
          />

          <Route
            exact
            path="/bookings/confirmation/:bookingId"
            render={(props) => <PaymentStatus location={props} />}
          />

          <Route
            exact
            path="/checkout/:bookingId"
            render={(props) => <PaymentPage location={props} />}
          />

          <Route
            exact
            path="/rooms/:id/:checkin/:checkout/:adult/:children"
            render={(props) => <MainPage location={props} />}
          />

          <Route
            exact
            path="/redirect"
            render={(props) => <RedirectPage location={props} />}
          />

          <Route
            exact
            path="/rooms/:id/:checkin/:checkout/:adult/:children/:infant"
            render={(props) => <MainPage location={props} />}
          />

          <Route
            exact
            path="/room/checkout"
            render={(props) => <Checkout location={props} />}
          />

          <Route
            exact
            path="/privacy"
            render={(props) => <ChatPrivacy location={props} />}
          />

          <Route
            exact
            path="/move"
            render={(props) => <Move location={props} />}
          />

          <Route
            exact
            path="/room-view/:id"
            render={(props) => <RoomView location={props} />}
          />

          <Route
            exact
            path="/pagenotfound"
            render={(props) => <PageNotFound location={props} />}
          />

          <Route
            exact
            path="/:slug/privacy-policy"
            render={(props) => (
              <HotelPrivacyPolicy location={props} host={isHost} />
            )}
          />

          <Route
            exact
            path="/sitemaps"
            render={(props) => <Sitemaps location={props} host={isHost} />}
          />

          <Route
            exact
            path="/:slug/terms-conditions"
            render={(props) => <HotelTerms location={props} host={isHost} />}
          />

          <Route
            exact
            path="/privacy-policy"
            render={(props) => <PrivacyIndex location={props} host={isHost} />}
          />

          <Route
            exact
            path="/terms-conditions"
            render={(props) => <TermsIndex location={props} host={isHost} />}
          />

          <Route
            exact
            path="/review"
            render={(props) => <ReviewPage location={props} />}
          />

          <Route
            exact
            path="/planner/:id"
            render={(props) => <TripPlanner location={props} />}
          />

          {/* <Route
          exact
          path="/:slug/room"
          render={(props) => <MainPage location={props} />}
        /> */}

          <Route
            exact
            path="/:slug"
            render={(props) => isHost && <MainPage location={props} />}
          />
          <Route component={PageNotFound} />
        </Switch>
      </Suspense>
    </>
  );
};

export default withRouter(Home);
