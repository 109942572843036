import dayjs from "dayjs";

var IMAGE_QUALITY = 100;

if (window?.navigator?.connection?.effectiveType === "3g") {
  IMAGE_QUALITY = 60;
} else if (window?.navigator?.connection?.effectiveType === "2g") {
  IMAGE_QUALITY = 40;
} else if (window?.navigator?.connection?.effectiveType === "slow-2g") {
  IMAGE_QUALITY = 20;
}

const instaProps = {
  493: "STRUNO6JPV6",
  1613: "STROFRCDXYU",
  372: "STRV4E23013",
  433: "STRUR3FEESB",
  1618: "STRNG84A7BP",
};

const deviceType =
  window.innerWidth < 768
    ? "Mobile"
    : window.innerWidth < 1024
    ? "Tablet"
    : "Desktop";

const instaPropsArr = [493, 1613, 372, 433, 1618];

// const excludedProperties = [
//   1931, 1755, 451, 1743, 1441, 1723, 692, 1640, 1413, 403, 2297, 1853, 1404,
//   2294, 429, 1780, 2307, 354, 1579, 1600, 1935, 1991, 2127, 1930, 1483, 1637,
//   2024, 1551, 2104, 1283, 2089, 1703, 1272, 2116, 1859, 2017, 1974, 1706, 1822,
//   1799, 1807, 428, 2008, 2122, 1800, 1699, 2121, 1989, 1944, 1590, 2241, 1520,
//   591, 1428, 1635, 1757, 1884, 1993, 1416, 1748, 1717, 1410, 1975, 498, 1445,
//   1667, 1858, 1480, 503, 2015, 1375, 2166, 2191, 1806, 1726, 1734, 1542, 2208,
//   1883, 1805, 2249, 2026, 1421, 2277, 1685, 1255, 1484, 2209, 1577, 1376, 1771,
//   1776, 1322, 1424, 1783, 1810, 1803, 1990, 1690, 1655, 1746, 1653, 1733, 1656,
//   1714, 1910, 691, 1292, 1433, 1821, 1727, 1366, 1847, 1268, 2366, 1905, 1676,
//   572, 1406, 1929, 2377, 2355, 1902, 1899, 1871, 1789, 1563, 971, 1397, 1837,
//   1755, 451, 1743, 1441, 1723, 692, 1640, 1413, 403, 2297, 1853, 1404, 2294,
//   429, 1780, 2307, 354, 1579, 1600, 1935, 1991, 2127, 1930, 1483, 1637, 2024,
//   1551, 2104, 1283, 2089, 1703, 1272, 2116, 1859, 2017, 1974, 1706, 1822, 1799,
//   1807, 2008, 428, 2122, 1800, 1699, 2121, 1989, 1944, 1590, 2241, 1520, 591,
//   1428, 1635, 1757, 1884, 1993, 1416, 1748, 1717, 1410, 1975, 498, 1445, 1667,
//   1858, 1480, 503, 2015, 1375, 2166, 2191, 1806, 1726, 1734, 1542, 2208, 1883,
//   1805, 2249, 2026, 1421, 2277, 1685, 1255, 1484, 2209, 1577, 1376, 1771, 1776,
//   1322, 1424, 1783, 1810, 1803, 1990, 1690, 1655, 1746, 1653, 1733, 1656, 1714,
//   1910, 691, 1292, 1433, 1821, 1727, 1366, 1847, 1268, 2366, 1905, 1676, 572,
//   1406, 1929, 2377, 2355, 1902, 1899, 1871, 1789, 1563, 529,
// ];

const tawkProps = [];

export const bot9Ids = [5157, 5151, 4607];

export const bot9Keys = {
  5157: "90715633-e048-41f9-8bc5-ee4ac465a997",
  // 529: "90715633-e048-41f9-8bc5-ee4ac465a997",
  5151: "04b890ff-5376-4c52-a94f-235c97dd0175",
  4607: "f0b1c1bc-d89c-4e78-ad45-0a1de7f7c05c",
  // 5158: "9c64f43a-c161-4d5c-8aa1-c3eac5968a1b",
};

export const getFixedValue = (currency) =>
  ["INR", "IDR"].includes(currency) ? 0 : 2;

export const inhotelIds = {
  2269: "b1237b9c-3496-44d2-85ac-e6b4ea876385",
  // 372: "c00d76e7-7af6-443c-9065-da294b1a49bc",
  2740: "6348df3d-bc87-4bb7-8ec1-961a426113cf",
  1613: "98dba7b1-ca12-4669-a9b7-fe3c34950d87",
  1480: "3305acf5-763c-49d5-ad20-1137f21f231f",
  349: "44535994-7a58-450f-9839-385902bff713",
  493: "7da1a772-3102-46e5-b224-825c9a23317f",
  2297: "41a6df9a-c07f-4aa6-ab8c-5bb1855dc4b3",
  2776: "4dda4361-aad5-4d3f-bdfe-18db71fcea87",
  1720: "383b1ad3-968f-4846-9a43-75fb04f2ec27",
};

export const formatNumber = (num2) => {
  let num = Number(num2);
  if (num >= 100000) {
    let formattedNum = (num / 100000).toFixed(1);
    return (
      (formattedNum % 1 === 0 ? parseInt(formattedNum) : formattedNum) + "L"
    );
  } else if (num >= 1000) {
    let formattedNum = (num / 1000).toFixed(1);
    return (
      (formattedNum % 1 === 0 ? parseInt(formattedNum) : formattedNum) + "k"
    );
  } else {
    return num.toString();
  }
};

const scrollProperties = [
  1974, 1236, 1579, 1975, 1760, 1587, 1762, 1792, 1277, 2105, 657, 1807, 1889,
  1646, 692, 1670, 1750, 1747, 1640, 1837, 1278, 1945, 641, 1619, 1431, 1605,
  403, 2000, 2032, 354, 339, 1283, 1902, 1594, 1749, 1703, 1336, 1397, 1874,
  1756, 373, 1612, 2014, 1720, 2033, 1986, 1988, 2116, 1491, 1452, 907, 1677,
  2112, 517, 1388, 2061, 696, 493, 383, 655, 1655, 1721, 1613, 1487, 462, 1984,
  1475, 1480, 1824, 2057, 1622, 1441, 1843, 1296, 2085, 1307, 1718, 451, 1916,
  1915, 1790, 2052,
]; // reduced from 113 to 84 (removed props which did not get more than 10%)

export const sourceTest = ["Google", "google", "webShare", "appShare"];

const manualDefaultPlaces = {
  1760: [
    {
      name: "Kempegowda International Airport",
      geometry: {
        location: {
          lat: 13.1989089,
          lng: 77.70681309999999,
        },
      },
    },
    {
      name: "Padukone - Dravid Centre for Sports Excellence",
      geometry: {
        location: {
          lat: 13.1677366,
          lng: 77.59810379999999,
        },
      },
    },
    {
      name: "Reva University",
      geometry: {
        location: {
          lat: 13.1168745,
          lng: 77.6346118,
        },
      },
    },
    {
      name: "Sir MVIT",
      geometry: {
        location: {
          lat: 13.1510405,
          lng: 77.6100173,
        },
      },
    },
    {
      name: "Nandi Hills",
      geometry: {
        location: {
          lat: 13.370154,
          lng: 77.68345510000002,
        },
      },
    },
    {
      name: "Sri Venkateshwara Engineering College",
      geometry: {
        location: {
          lat: 13.1596355,
          lng: 77.63616739999999,
        },
      },
    },
    {
      name: "Cytecare Cancer hospital",
      geometry: {
        location: {
          lat: 13.1183406,
          lng: 77.60826949999999,
        },
      },
    },
  ],
  2116: [
    {
      name: "Kempegowda International Airport",
      geometry: {
        location: {
          lat: 13.1989089,
          lng: 77.70681309999999,
        },
      },
    },
    {
      name: "Nandi Hills",
      geometry: {
        location: {
          lat: 13.370154,
          lng: 77.68345510000002,
        },
      },
    },
    {
      name: "Devanahalli port",
      geometry: {
        location: {
          lat: 13.2494611,
          lng: 77.7090517,
        },
      },
    },
    {
      name: "Isha Foundation Chikkaballapura",
      geometry: {
        location: {
          lat: 13.4864375,
          lng: 77.7058125,
        },
      },
    },
    {
      name: "Reva University",
      geometry: {
        location: {
          lat: 13.1168745,
          lng: 77.6346118,
        },
      },
    },
    {
      name: "Chanakya University",
      geometry: {
        location: {
          lat: 13.2314729,
          lng: 77.7228427,
        },
      },
    },
    {
      name: "KIADB Aerospace SEZ",
      geometry: {
        location: {
          lat: 13.1741689,
          lng: 77.7313163,
        },
      },
    },
    {
      name: "KIADB Industrial Area Devanahalli",
      geometry: {
        location: {
          lat: 13.2437197,
          lng: 77.7172413,
        },
      },
    },
  ],
  1188: [
    {
      name: "Chinmay Vibhooti Ashram",
      geometry: {
        location: {
          lat: 18.578083,
          lng: 73.540046,
        },
      },
    },
    {
      name: "Hadshi / Pran Pandharpur Temple",
      geometry: {
        location: {
          lat: 18.58252,
          lng: 73.513681,
        },
      },
    },
    {
      name: "Tikona Fort",
      geometry: {
        location: {
          lat: 18.631843,
          lng: 73.51275,
        },
      },
    },
    {
      name: "Pawna Lake",
      geometry: {
        location: {
          lat: 18.666848,
          lng: 73.485783,
        },
      },
    },
    {
      name: "Lohgad Fort",
      geometry: {
        location: {
          lat: 18.710195,
          lng: 73.475862,
        },
      },
    },
    {
      name: "Kamshet Railway Station",
      geometry: {
        location: {
          lat: 18.761137,
          lng: 73.557206,
        },
      },
    },
    {
      name: "Tamini Ghat",
      geometry: {
        location: {
          lat: 18.475911,
          lng: 73.459242,
        },
      },
    },
    {
      name: "Lonavala",
      geometry: {
        location: {
          lat: 18.755724,
          lng: 73.409076,
        },
      },
    },
  ],
  4152: [
    {
      name: "Solitaire Park",
      geometry: {
        location: {
          lat: 26.816208,
          lng: 75.553962,
        },
      },
    },
    {
      name: "Manipal University",
      geometry: {
        location: {
          lat: 26.839906,
          lng: 75.564179,
        },
      },
    },
  ],
};

const excludedProperties = [];

export function hasSpecialCharacters(text) {
  var specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

  for (var i = 0; i < text.length; i++) {
    if (specialCharacters.test(text[i])) {
      return true;
    }
  }
  return false;
}

export const anirudhWhatsappNumber = [372];

export const pluralWords = (count, word) => (count > 1 ? `${word}s` : word);

const domains = [
  "dev.hotelzify.com",
  "hotelzify.com",
  "www.hotelzify.com",
  "localhost:3005",
];
const isDev =
  window.location.host.includes("localhost") ||
  window.location.host.includes("dev.hotelzify");
const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
const isPopupBlocked =
  !window.navigator ||
  (window.navigator &&
    window.navigator.userAgent.match(
      /(iPad|iPhone|iPod touch);.*CPU.*OS 7_\d/i
    ))
    ? false
    : true;

const keywordUTM = {
  google_source: "googleSource",
  user_list_id: "userListId",
  utm_campaign: "utmCampaign",
  utm_source: "utmSource",
};

const convertAgeToDOB = (age) => {
  var currentYear = dayjs().year();
  var birthYear = currentYear - age;
  let db = dayjs(new Date(birthYear, dayjs().month(), dayjs().date())).format(
    "YYYY/MM/DD"
  );
  return db;
};

const convertDobToAge = (dob) => {
  let dobYear = dayjs(dob).year();
  let currentYear = dayjs().year();
  return currentYear - dobYear || "";
};

const shouldSendEvent = true; // process.env.REACT_APP_IS_PROD === "true";

const removeSpecialCharacters = (str) => {
  // Use a regular expression to match all special characters and replace them with spaces
  return str?.replace(/[^\w\s]/gi, " ");
};

const couponHotels = [2297];

const colorHotels = [
  1966, 1984, 1720, 380, 1988, 2303, 2297, 1913, 1360, 671, 1986, 2269, 529, 10,
];

const oyoColorHotels = [1966, 1984, 1988, 2297, 2303];
const mmtColorHotels = [380, 671, 1360, 1720, 1913, 1986, 2269];

export function isDarkColor(r, g, b) {
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance < 0.5;
}

const timeSlots = [
  "12:00 AM",
  "01:00 AM",
  "02:00 AM",
  "03:00 AM",
  "04:00 AM",
  "05:00 AM",
  "06:00 AM",
  "07:00 AM",
  "08:00 AM",
  "09:00 AM",
  "10:00 AM",
  "11:00 AM",
  "12:00 PM",
  "01:00 PM",
  "02:00 PM",
  "03:00 PM",
  "04:00 PM",
  "05:00 PM",
  "06:00 PM",
  "07:00 PM",
  "08:00 PM",
  "09:00 PM",
  "10:00 PM",
  "11:00 PM",
];

export const EXCLUDE_DISCOUNT_ROOM = [4183, 9420, 8986, 8987];

function addHoursToTime(hourlyDate, startTime, hoursToAdd, wantDate = false) {
  // Parse the startTime string into a Day.js object

  const dateTimeString = `${dayjs(hourlyDate).format(
    "DD MMM YY"
  )} ${startTime}`;

  // Add the specified number of hours
  const updatedTimeObj = dayjs(dateTimeString).add(hoursToAdd, "hour");

  if (wantDate) {
    let date = dayjs(updatedTimeObj).format("YYYY/MM/DD");
    let time = dayjs(updatedTimeObj).format("hh:mm A");
    return { date, time };
  }

  // Format the updated time as 'hh:mm AM/PM'
  const updatedTimeStr = dayjs(updatedTimeObj).format("hh:mm A");

  return updatedTimeStr;
}

function convert24hrTo12hr(time24hr = "00:00:00") {
  const splitTime = time24hr.split(":");
  let period = "AM";

  let hours12 = parseInt(splitTime[0], 10);

  if (hours12 >= 12) {
    period = "PM";

    if (hours12 >= 24) {
      hours12 = 0;
      period = "AM";
    } else if (hours12 > 12) {
      hours12 -= 12;
    }
  }
  if (hours12 === 0) {
    hours12 = 12;
  }

  return `${hours12.toString().padStart(2, "0")}:${splitTime[1]} ${period}`;
}

function isStartTimeBeforeEndTime(startTime, endTime) {
  // Parse the time strings using dayjs
  const start = dayjs(`08-10-2023 ${startTime}`);
  const end = dayjs(`08-10-2023 ${endTime}`);

  // Check if the start time is before the end time
  return !start.isAfter(end);
}

function convert12hrTo24hr(time12hr) {
  const [time, period] = time12hr.split(" ");
  const [hours, minutes] = time.split(":");

  let hours24 = parseInt(hours, 10);

  if (period === "PM" && hours24 !== 12) {
    hours24 += 12;
  } else if (period === "AM" && hours24 === 12) {
    hours24 = 0;
  }

  return `${hours24.toString().padStart(2, "0")}:${minutes}`;
}

function removeDuplicateObjectsById(arr) {
  const uniqueObjects = {};
  const result = [];

  for (const obj of arr) {
    const id = obj.id;

    // If the object with this id hasn't been added yet, add it to the result array
    if (!uniqueObjects[id]) {
      result.push(obj);
      uniqueObjects[id] = true;
    }
  }

  return result;
}

export const distanceCalculation = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the Earth in kilometers
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in kilometers
  return distance?.toFixed(2);
};

export function sortRoomsByPriceAndAvailability(rooms) {
  let availRooms = rooms?.filter((rm) => rm?.availableRooms > 0);

  let nonAvailRooms = rooms?.filter((rm) => rm?.availableRooms === 0);

  function getMinPriceBeforeTax(pricingObj) {
    if (!pricingObj) return;
    return Math.min(...pricingObj.priceForPax.map((p) => p.priceBeforeTax));
  }

  availRooms.forEach((obj) => {
    obj.pricing.sort(
      (a, b) => getMinPriceBeforeTax(a) - getMinPriceBeforeTax(b)
    );
  });

  availRooms.sort(
    (a, b) =>
      getMinPriceBeforeTax(a.pricing[0]) - getMinPriceBeforeTax(b.pricing[0])
  );

  return [...availRooms, ...nonAvailRooms];
}

export const getApplicableTax = ({ taxes = [], amount, level }) => {
  return taxes.filter((tax) => {
    const isNotLevel = level && tax.level !== level;
    const notPriceFrom = tax.priceFrom && amount < tax.priceFrom;
    const notPriceTo = tax.priceTo && amount > tax.priceTo;
    return !isNotLevel && !notPriceFrom && !notPriceTo;
  });
};

export const getCalculatedTax = ({
  taxes = [],
  rooms,
  isInclusive = true,
  noOfNights = 1,
}) => {
  const [roomTaxes, bookingTaxes] = taxes.reduce(
    ([roomTaxes, bookingTaxes], tax) => {
      (tax.level === "room" ? roomTaxes : bookingTaxes).push(tax);
      tax.totalTax = 0;
      return [roomTaxes, bookingTaxes];
    },
    [[], []]
  );
  const appliedTaxes = [];
  const calculateTax = ({ roomId = null, taxes, amount, level }) => {
    let amountAfterTax = amount;
    let roomTax = 0;
    taxes
      .sort((a, b) => a.index - b.index)
      .forEach((tax) => {
        const usingAmount = tax.useIndex ? amountAfterTax : amount;
        let taxAmount = tax.tax;
        if (tax.taxType === "percentage") {
          if (isInclusive) {
            taxAmount = usingAmount - usingAmount / (1 + tax.tax / 100);
          } else {
            taxAmount = (usingAmount * tax.tax) / 100;
          }
        }
        const amountWithoutTax = usingAmount - (isInclusive ? taxAmount : 0);
        const isNotLevel = level && tax.level !== level;
        const notPriceFrom = tax.priceFrom && amountWithoutTax < tax.priceFrom;
        const notPriceTo = tax.priceTo && amountWithoutTax > tax.priceTo;
        if (isNotLevel || notPriceFrom || notPriceTo) {
          return;
        }
        if (tax.taxType === "fixed" && tax.level === "room") {
          taxAmount = taxAmount * (noOfNights || 1);
        }
        roomTax += taxAmount;
        amountAfterTax += isInclusive ? -taxAmount : taxAmount;
        appliedTaxes.push({
          roomId,
          tax,
          taxAmount,
          level,
        });
        tax.totalTax = (tax.totalTax ?? 0) + taxAmount;
      });
    return roomTax;
  };
  let totalAmount = 0;
  let totalRoomTax = 0;
  rooms.forEach((room) => {
    const roomTax = calculateTax({
      roomId: room.id,
      taxes: roomTaxes,
      amount: room.amount,
      level: "room",
    });
    room.roomTax = roomTax;
    totalAmount += room.amount;
    totalRoomTax += roomTax;
  });
  const totalBookingTax = calculateTax({
    taxes: bookingTaxes,
    amount: totalAmount,
    level: "booking",
  });
  return {
    totalTax: totalRoomTax + totalBookingTax,
    taxes: taxes.sort((a, b) => a.index - b.index),
    rooms,
    totalRoomTax: isInclusive ? -totalRoomTax : totalRoomTax,
    totalBookingTax,
    appliedTaxes,
  };
};

export const removeDuplicates = (arr, key) => {
  const seen = new Set();
  return arr.filter((item) => {
    const id = item[key];
    if (seen.has(id)) {
      return false;
    } else {
      seen.add(id);
      return true;
    }
  });
};

export {
  IMAGE_QUALITY,
  isStartTimeBeforeEndTime,
  convertDobToAge,
  excludedProperties,
  convert12hrTo24hr,
  domains,
  removeSpecialCharacters,
  isIOS,
  isSafari,
  isPopupBlocked,
  instaProps,
  instaPropsArr,
  isDev,
  keywordUTM,
  deviceType,
  shouldSendEvent,
  convertAgeToDOB,
  scrollProperties,
  timeSlots,
  addHoursToTime,
  convert24hrTo12hr,
  manualDefaultPlaces,
  couponHotels,
  removeDuplicateObjectsById,
  tawkProps,
  colorHotels,
  oyoColorHotels,
  mmtColorHotels,
};
